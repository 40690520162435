.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.countdown-desc {
    font-size: x-small
}

.nav-links {
    padding: 15px;
}

.App-body {
    background-color: #282c34;
    min-height: 100vh;
}

.home-container,
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.countdown-container {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

textarea {
  color: black !important
}

a {
  color: hotpink;
}

.countdown {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}

.countdown-time-container {
  display: flex;
  flex-direction: row;
}

.table-container-champs {
  width: 100%;
  overflow-x: auto;
}

.table-container-champs table {
  width: auto;
  border-collapse: collapse;
}

.table-container-champs th,
.table-container-champs td {
  padding: 8px;
  border: 1px solid #ccc;
}

.table-container-champs th:first-child,
.table-container-champs td:first-child {
  position: sticky;
  left: 0;
  background-color: #0d4618;
  z-index: 2;
}
