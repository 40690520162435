.sub-nav {
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.sub-nav::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.sub-nav a {
    white-space: nowrap;
}

.history-nav, .history-sub-nav, .seasons-sub-nav {
    width: 100%;
    text-align: center;
    padding: 10px;
}

.history-table-nav {
    display: flex;
    margin: auto;
    width: 50%;
    justify-content: space-evenly;
}

.seasons-table-nav {
    width: 100%;
    height: 35px;
    overflow: hidden;
    position: relative;
    display: flex;
}



#users-table, #season-standings-container, .seasons-table-nav, #season-playoffs-container {
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

#users-table::-webkit-scrollbar, #season-standings-container::-webkit-scrollbar, .seasons-table-nav::-webkit-scrollbar, #season-playoffs-container::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

#users-table table, #season-standings-container table, .seasons-table-nav > div {
    margin: auto;
}

#history-page table th {
    white-space: nowrap;
    padding: 0 5px;
}

#season-playoffs-container {
    display: flex;
    margin: auto;
    flex-direction: column;
    margin-top: 25px;
    padding: 0 10px 40px;
}

.playoff-weeks-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 50px;
}

.playoff-weeks-headers {
    display: flex;
    margin: auto;
}
.playoff-week-header {
    min-width: 140px;
    text-align: center;
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.playoff-weeks {
    display: flex;
    margin: auto;
    align-items: center;
}

.playoff-week-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.playoff-week-empty-container {
    min-width: 140px;
}

.playoff-matchup-container > div {
    min-width: 120px;
    display: flex;
    justify-content: space-between;
}

#season-playoffs-container h2, #season-standings-container h2 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
    font-weight: bold;
    font-size: 1.3rem;
    text-align: center;
}

#season-playoffs-container h3 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
    font-size: 1.1rem;
    text-align: center;
}

.playoff-matchup-container {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
}

.playoff-matchup-container > .matchup-loser {
    --tw-text-opacity: .3;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.playoff-matchup-container > .matchup-loser.inverted {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.playoff-matchup-container > .matchup-winner.inverted {
    --tw-text-opacity: .3;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
}