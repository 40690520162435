.countdown-time-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 15px;
}

.countdown-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.countdown-time {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    width: 65px;
    border: 1px solid #fff;
}

.countdown-label {
    font-size: .85rem;
}